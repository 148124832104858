import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";

function Form() {
  const form = useRef();
  const sendEmail = (event) => {
    event.preventDefault();
    emailjs
      .sendForm(
        "service_t4nb85n",
        "template_x05p8j8",
        form.current,
        "4JRBoKphp9jEM56V7"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Your mail has been sent successfully");
          window.location.reload(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };
  return (
    <div className="bg-gradient-to-b from-[#CDB345] to-[#FDF4CA] p-0.5 rounded-[40px]   md:w-3/4">
      <div className="bg-[#FFFFFF] rounded-[40px] py-6 px-10 md:py-10 md:px-12 ">
        <div className=" text-base md:text-xl lg:text-[24px] text-[#CEB446] leading-none text-center font-medium">
          <h3>
            Schedule a free <br />
            walkthrough
          </h3>
        </div>
        <form
          className="mt-5 md:mt-10"
          ref={form}
          onSubmit={sendEmail}
          //   onSubmit={handleSubmitt}
        >
          <div className=" flex flex-col space-y-6  ">
            {/* Name Field */}
            <div className="flex flex-col space-y-4 w-full">
              <label
                htmlFor="name"
                className="text-[#CEB446] font-Monamd text-base md:text-lg leading-none "
              >
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="outline-none bg-transparent border-b border-[#CEB446]   text-sm md:text-base text-[#CEB446] font-Monamd"
                value={userDetails.name}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Email Field */}
            <div className=" flex flex-col space-y-4 w-full">
              <label
                htmlFor="email"
                className="text-[#CEB446] font-Monamd text-base md:text-lg leading-none "
              >
                Email:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="outline-none bg-transparent border-b border-[#CEB446]   text-sm md:text-base text-[#CEB446] font-Monamd"
                value={userDetails.email}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Phone Field */}
            <div className=" flex flex-col space-y-4 w-full">
              <label
                htmlFor="phone"
                className="text-[#CEB446] font-Monamd text-base md:text-lg leading-none "
              >
                Phone:
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="outline-none bg-transparent border-b border-[#CEB446]   text-sm md:text-base text-[#CEB446] font-Monamd"
                value={userDetails.phone}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          {/* Send Button */}
          <button
            type="submit"
            className="py-4 bg-[#CEB446] flex w-full text-center justify-center text-white rounded-[14px] text-base font-Monamd mt-6 md:mt-11"
          >
            Schedule Now
          </button>
        </form>
      </div>
    </div>
  );
}

export default Form;
