import React from "react";
import Service from "./Service";
import img from "../../Assets/img/CorporateCleaning.png"

function Corporatecleaning() {
    
  const data = [
    {
      title: "Comprehensive Office Cleaning",
      description:
        "Our skilled team ensures every corner of your office is cleaned to perfection, from workstations to common areas, creating a tidy and productive environment.",
    },
    {
      title: "Sanitized High-Touch Areas",
      description:
        "We focus on sanitizing high-touch surfaces like door handles, desks, and electronics, reducing the spread of germs and ensuring a healthier workspace.",
    },
    {
      title: "Flexible Cleaning Schedules",
      description:
        "We offer custom cleaning plans to fit your business hours and needs, ensuring minimal disruption to your daily operations while maintaining a pristine environment.",
    },
    {
      title: "Eco-Friendly Cleaning Solutions",
      description:
        "Our eco-conscious cleaning methods prioritize the health of your employees and the environment, using green-certified products and sustainable practices.",
    },
  ];

  return (
    <>
      <Service
        title="Corporate Cleaning"
        subheading="Professional Cleanliness"
        description="Maintaining a clean and organized corporate environment is essential for the productivity, health, and morale of your employees and visitors. Our Corporate Cleaning service is tailored to meet the unique needs of corporate offices, ensuring every space is spotless and sanitized. We handle everything from desks and meeting rooms to kitchens and restrooms, using eco-friendly products and advanced cleaning methods."
        services={data} // Assuming `data` contains the services related to corporate cleaning
        bannerbg={img}
        conclusion="With our professional Corporate Cleaning service, we ensure a pristine workplace that fosters professionalism and efficiency. Our cleaning schedules are flexible and can be customized to your business needs, whether it's daily, weekly, or monthly."
      />
    </>
  );
}

export default Corporatecleaning;
