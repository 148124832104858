import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Services.css";
import ContactSection from "../Contactsection";

function Service({
  title,
  subheading,
  description,
  services,
  bannerbg,
  conclusion,
}) {
  return (
    <>
      <Header />
      <section
        className="servicebanner thumbnail"
        style={{
          backgroundImage: `url(${bannerbg})`, // Use dynamic URL if image is imported
          backgroundSize: "cover", // Cover the entire div
          backgroundPosition: "center", // Center the image
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 data-aos="fade-down" className="text-[#CEB445] text-lg md:text-xl lg:text-[26px]">Services</h3>
              <h2 data-aos="fade-up" className="text-3xl md:text-4xl lg:text-[44px]">{title}</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="servicetab-section" style={{ padding: "80px 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 tab-content">
              <h2 className="text-[#CEB446]">
                <span>{title}</span> -{subheading}
              </h2>
              <h6>{description}</h6>
              <div className="cards-container">
                {services.map((service, index) => (
                  <div
                    className="bg-gradient-to-b from-[#CDB345] to-[#FDF4CA] p-0.5 rounded-[40px]"
                    key={index}
                  >
                    <div className="card group hover:bg-[#CEB445] !rounded-[40px] ">
                      <h6 className="text-[#CEB446] group-hover:text-white text-base md:text-lg lg:text-[20px]">
                        {service.title}
                      </h6>
                      <p className=" group-hover:text-white text-sm md:text-base">
                        {service.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="rounded-[40px] bg-[#CEB446] flex flex-col md:flex-row gap-10 items-center justify-between px-10 py-12">
                <h6 className="text-white max-w-3xl">{conclusion}</h6>
                <a
                  href="/contact-us"
                  className="rounded-[14px] py-4 px-12 bg-white text-[#CEB446] self-center "
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactSection />
      <Footer />
    </>
  );
}

export default Service;
