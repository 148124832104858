import React from "react";
import { Helmet } from "react-helmet";
import Service from "./Service";
import img from "../../Assets/img/MedicalCleaning.png"

function Medicalcleaning() {
  const data = [
    {
      title: "Comprehensive Surface Disinfection",
      description:
        "Utilizing medical-grade disinfectants to ensure thorough cleaning and sanitization.",
    },
    {
      title: "High-Touch Surface Cleaning",
      description:
        "Focusing on areas like doorknobs and medical equipment to reduce cross-contamination.",
    },
    {
      title: "Examination Room Sanitization",
      description:
        "Meticulous cleaning and preparation of examination rooms for patient use.",
    },
    {
      title: "Waiting Area Maintenance",
      description:
        "Regular cleaning of seating, tables, and floors in waiting areas for patient comfort.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Medical Cleaning Services | MM Build & Maintenance</title>
        <meta
          name="description"
          content="Ensure a sterile environment with MM Build & Maintenance’s medical cleaning services. Expert, compliant service for healthcare facilities. Request a free quote!"
        />
        <meta name="keywords" content="Medical Cleaning Services" />
      </Helmet>
      <Service
        title="Medical Cleaning"
        subheading="Gleaming Perfection"
        description="Sanitation is essential for healthcare environments, where
                cleanliness directly impacts patient safety and well-being. Our
                medical cleaning services are designed to meet the highest
                standards of hygiene and infection control. With specialized
                training and adherence to stringent protocols, our team ensures
                thorough disinfection of all surfaces, including waiting areas,
                examination rooms, and high-touch surfaces."
        services={data}
        bannerbg={img}
        conclusion="Our commitment to quality and safety means you can trust us to
                provide a clean environment that promotes patient health and
                complies with all regulatory requirements. We understand the
                unique challenges of healthcare facilities, and our flexible
                scheduling allows us to perform our services with minimal
                disruption to your operations."
      />
    </>
  );
}

export default Medicalcleaning;
