import React from "react";
import { Helmet } from "react-helmet";
import Service from "./Service";
import img from "../../Assets/img/Group828.png"

function PressureWashing() {
  const data = [
    {
      title: "Exterior Building Facades",
      description:
        "Remove dirt, algae, and other contaminants to restore your building’s original beauty.",
    },
    {
      title: "Sidewalks and Walkways",
      description:
        "Ensure safe, clean pathways by removing dirt, gum, oil stains, and mildew.",
    },
    {
      title: "Parking Lots and Driveways",
      description:
        "Enhance curb appeal and prolong the life of surfaces by removing oil, grease, and tire marks.",
    },
    {
      title: "Decks, Patios, and Fences",
      description:
        "Revitalize wooden, concrete, or composite surfaces by eliminating dirt, mold, and grime buildup.",
    },
  ];
  return (
    <>
      <Service
        title="Pressure Washing"
        subheading="Reviving Your Surfaces"
        description=" Over time, dirt, grime, mold, and mildew can build up on various
                surfaces, diminishing the overall appeal of your property. Our
                pressure washing service will restore the beauty of your
                exteriors, including sidewalks, parking lots, and building
                facades, bringing them back to their original shine."
        services={data}
        bannerbg={img}
        conclusion="Whether it’s for commercial or residential properties, our
                pressure washing service can enhance the look of your exteriors
                and maintain their integrity for years to come. We offer
                flexible scheduling and custom cleaning plans to suit your
                specific needs, ensuring every surface shines like new."
      />
    </>
  );
}

export default PressureWashing;
