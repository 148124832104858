import React from "react";
import { Helmet } from "react-helmet";
import Service from "./Service";
import img from "../../Assets/img/CommercialWindowCleaning.png";

const Commercialcleaning = () => {
  const data = [
    {
      title: "Crystal Clear Glass Surfaces",
      description:
        "Our expert cleaners ensure spotless glass, allowing natural light to brighten up your workspace and create a welcoming environment.",
    },
    {
      title: "Streak-Free Results",
      description:
        "We use professional techniques and tools to provide streak-free results, ensuring your windows look their best.",
    },
    {
      title: "Interior and Exterior Cleaning",
      description:
        "Our service includes both interior and exterior window cleaning, leaving every pane spotless inside and out.",
    },
    {
      title: "Custom Schedules to Suit Your Business",
      description:
        "We offer flexible cleaning schedules to fit your business needs, minimizing disruption while maintaining cleanliness.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Commercial Window Cleaning Services | MM Build & Maintenance
        </title>
        <meta
          name="description"
          content="Get streak-free, crystal-clear windows with MM Build & Maintenance's expert Commercial  Window Cleaning Service. Request a quote now!"
        />
        <meta name="keywords" content="Commercial Window Cleaning Services" />
      </Helmet>
      <Service
        title="Commercial Window Cleaning"
        subheading="A Clear View"
        description=" First impressions are crucial, and sparkling clean windows can
                elevate the perception of your business. Our professional
                commercial window cleaning service ensures that your glass
                surfaces are spotless, allowing natural light to flood into your
                space and creating a welcoming environment for your customers
                and employees alike. We use safe and effective cleaning methods
                that leave your windows streak-free and gleaming."
        services={data}
        bannerbg={img}
        conclusion=" By maintaining the cleanliness of your windows, you not only
                enhance the aesthetic appeal of your property but also improve
                energy efficiency by maximizing natural light. Our team is fully
                trained and equipped to handle all types of commercial
                buildings, ensuring a professional and hassle-free service every
                time."
      />
    </>
  );
};

export default Commercialcleaning;
