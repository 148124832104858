import React from "react";
import Service from "./Service";
import img from "../../Assets/img/LightHandymanWork.png"

function Handymanwork() {
  const data = [
    {
      title: "Minor Repairs and Maintenance",
      description:
        "Our team handles small repairs such as fixing leaky faucets, tightening loose fixtures, and replacing light bulbs, ensuring your facility remains in top condition.",
    },
    {
      title: "Furniture Assembly",
      description:
        "We offer professional furniture assembly services, from desks and chairs to shelves and cabinets, ensuring a hassle-free setup for your workspace.",
    },
    {
      title: "Wall Repairs and Painting",
      description:
        "Our experts can patch up minor wall damage, repaint small areas, and touch up scuffed walls to maintain the aesthetic of your office or facility.",
    },
    {
      title: "Fixture Installation",
      description:
        "We assist with the installation of light fixtures, shelves, and other equipment to keep your space functional and organized.",
    },
  ];

  return (
    <>
      <Service
        title="Light Handyman Work"
        subheading="Keeping Your Workspace Functional"
        description="Our Light Handyman Work service helps with small but essential repairs and maintenance tasks around your office or facility. From fixing minor issues to assembling furniture, our team ensures that everything runs smoothly."
        services={data}
        bannerbg={img}
        conclusion="Whether it's regular upkeep or one-time fixes, we offer flexible handyman services to suit your needs. Our team can quickly address minor repairs and maintenance tasks to keep your workplace in excellent condition."
      />
    </>
  );
}

export default Handymanwork;
