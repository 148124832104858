import React from "react";
import { Helmet } from "react-helmet";
import Service from "./Service";
import img from "../../Assets/img/FloorCleaning.png"

function Floorcleaning() {
  const data = [
    {
      title: "Hardwood Cleaning",
      description:
        "We remove dirt and polish your hardwood floors for a lasting shine.",
    },
    {
      title: "Tile & Grout Cleaning",
      description:
        "Deep cleaning of tile and grout to eliminate stains and restore color.",
    },
    {
      title: "Vinyl Floor Care",
      description:
        "Restore the look of vinyl floors by removing scuffs and scratches.",
    },
    {
      title: "Stain Removal",
      description:
        "Safe, effective solutions to remove tough stains and odors.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Professional Floor Cleaning Services | MM Build & Maintenance
        </title>
        <meta
          name="description"
          content="Transform your floors with MM Build & Maintenance’s expert Floor cleaning services. Enjoy spotless results for any space. Get a free quote today!"
        />
        <meta name="keywords" content="Floor Cleaning Services" />
      </Helmet>
      <Service
        title="Floor Cleaning"
        subheading="Gleaming Perfection"
        description=" Floors are a major focal point in any space, and keeping them
                clean is essential to maintaining a polished, professional
                appearance. Whether you have hardwood, tile, vinyl, or other
                flooring materials, our floor cleaning service is designed to
                bring out the natural beauty of your floors while preserving
                their longevity. Using advanced techniques and top-of-the-line
                equipment, we remove dirt, grime, scuff marks, and stains,
                ensuring your floors look pristine."
        services={data}
        bannerbg={img}
        conclusion="Our floor cleaning service can be customized to fit the specific
                needs of your business or home. Whether it’s for high-traffic
                areas or regular maintenance, we ensure your floors remain
                spotless and vibrant, enhancing the overall appeal and
                cleanliness of your space."
      />
    </>
  );
}

export default Floorcleaning;
