import React from "react";
import { Helmet } from "react-helmet";
import Service from "./Service";
import img from "../../Assets/img/OfficeCleaning.png"
function Officecleaning() {
  const data = [
    {
      title: "Daily and Weekly Cleaning",
      description:
        "Customized cleaning schedules to fit your office's needs, ensuring your space remains consistently clean.",
    },
    {
      title: "Dusting and Vacuuming",
      description:
        "Comprehensive dusting of surfaces and thorough vacuuming to maintain air quality and eliminate allergens.",
    },
    {
      title: "Sanitizing Communal Areas",
      description:
        "Regular sanitization of high-touch surfaces to promote a healthy workplace.",
    },
    {
      title: "Restroom Cleaning",
      description:
        "Detailed cleaning and sanitization of restrooms to ensure they are always welcoming.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Expert Office Cleaning Services | MM Build & Maintenance</title>
        <meta
          name="description"
          content="Get a spotless, hygienic workspace with MM Build & Maintenance’s professional office cleaning services. Request your free quote today!"
        />
        <meta name="keywords" content="Office Cleaning Services" />
      </Helmet>
      <Service
        title="Office Cleaning"
        subheading=""
        description="  Our office cleaning services are designed to optimize the
                working environment, fostering productivity and professionalism.
                From bustling corporate headquarters to small-scale startups, we
                cater to diverse office settings with tailored cleaning
                solutions. Our dedicated team meticulously attends to every
                detail, from dusting and vacuuming to sanitizing communal areas
                and restrooms, creating a pristine and inviting workspace."
        services={data}
        bannerbg={img}
        conclusion=" By investing in professional office cleaning services, you
                create a healthier environment that enhances employee morale and
                productivity. Our flexible plans can be adapted to your schedule
                and specific needs, ensuring minimal disruption while providing
                a spotless workplace. With our commitment to quality and
                attention to detail, you can trust us to keep your office
                looking its best."
      />
    </>
  );
}

export default Officecleaning;
