import React from "react";
import Service from "./Service";
import bannerimg from "../../Assets/img/RestroomCleaning.png"
function Toiletcleaning() {
  const data = [
    {
      title: "Deep Cleaning and Disinfection",
      description:
        "Thorough cleaning and sanitization of all fixtures, ensuring that every surface is free from harmful bacteria and germs.",
    },
    {
      title: "Odor Control Solutions",
      description:
        "Our odor control solutions ensure that your restroom stays fresh and inviting.",
    },
    {
      title: "Stain Removal",
      description:
        "Effective removal of stains from tiles, grout, and other surfaces, leaving your restroom spotless.",
    },
    {
      title: "Stocking and Replenishment",
      description:
        "Restocking essential supplies such as Restroom paper, soap, and paper towels for your convenience.",
    },
  ];
  return (
    <Service
      title="Restroom Cleaning"
      subheading="Refreshing Cleanliness"
      description=" We understand that a clean and hygienic restroom is crucial for
                the well-being and comfort of your employees, customers, and
                visitors. That’s why our professional Restroom cleaning service
                is designed to maintain the highest standards of cleanliness.
                Our experienced team uses industry-grade cleaning agents and
                techniques to thoroughly clean and sanitize all areas, including
                Restroom bowls, seats, sinks, floors, and other high-touch
                surfaces."
      services={data}
      bannerbg={bannerimg}
      conclusion=" By maintaining a fresh, germ-free restroom, we help you create
                  a positive impression and a healthier environment. Whether you
                  need daily, weekly, or monthly cleaning, our flexible service
                  plans can be tailored to meet your specific requirements."
    />
  );
}

export default Toiletcleaning;
